/** @jsx jsx */
import React from "react"
import { jsx, Container, Heading, Text } from "theme-ui"
import styled from "@emotion/styled"
import { FaPhoneAlt, FaEnvelope, FaMapMarker } from "react-icons/fa"

export const Footer = () => (
  <footer sx={{ variant: `styles.footer` }}>
    <StyledContainer sx={{ variant: `styles.footer.container` }}>
      <Heading as="p" variant="title">
        Estamos a tus órdenes
      </Heading>
      <div sx={{ variant: `styles.footer.contact` }}>
        <a href="tel:+5527156263">
          <FaPhoneAlt />
          55.27.15.62.63
        </a>
        <a href="mailto:hola@tuflete.com.mx">
          <FaEnvelope />
          hola@tuflete.com.mx
        </a>
        <Text as="span">
          <FaMapMarker />
          Hacienda del Pedregal Secc Herradura, 52918 <br />
          Cd López Mateos, Méx.
        </Text>
      </div>
      <span sx={{ variant: `styles.footer.copyright` }}>
        © {new Date().getFullYear()} Tu Flete | Todos los derechos reservados
        <br />
        Desarrollado por{" "}
        <a
          href="https://www.facebook.com/CodeIncMX/"
          target="_blank"
          rel="nofollow"
        >
          Code Inc
        </a>
      </span>
    </StyledContainer>
  </footer>
)

const StyledContainer = styled(Container)`
  display: grid;
`
