/** @jsx jsx */
import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { jsx, Container, Button } from "theme-ui"
import { Drawer } from "antd"
import { FiMenu } from "react-icons/fi"
import { MdClose } from "react-icons/md"
import { GrFacebook, GrInstagram, GrTwitter, GrLinkedin } from "react-icons/gr"
import { OutboundLink } from "gatsby-plugin-google-gtag"
// import { trackCustomEvent, OutboundLink } from "gatsby-plugin-google-analytics"

import Logo from "../assets/isologo.svg"

export const Header = () => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <>
      <SideMenu onClose={onClose} visible={visible} />
      <HeaderContent showDrawer={showDrawer} />
    </>
  )
}

const SideMenu = ({ onClose, visible }) => (
  <Drawer
    placement="left"
    closable={false}
    onClose={onClose}
    visible={visible}
    bodyStyle={{ padding: 0 }}
  >
    <div sx={{ display: "grid", variant: `styles.header.drawer` }}>
      <div sx={{ variant: `styles.header.drawer.close` }}>
        <MdClose onClick={onClose} />
      </div>
      <ul sx={{ variant: `styles.header.drawer.menu` }}>
        <li>
          <Link to="/" activeClassName="active">
            Inicio
          </Link>
        </li>
        <li>
          <Link to="/flete-cdmx" activeClassName="active">
            Fletes CDMX
          </Link>
        </li>
        <li>
          <Link to="/fletes-estado-de-mexico" activeClassName="active">
            Fletes Estado de México
          </Link>
        </li>
        <li>
          <Link to="/fletes-atizapan" activeClassName="active">
            Fletes Atizapán
          </Link>
        </li>
        <li>
          <Link to="/nosotros" activeClassName="active">
            ¿Quiénes somos?
          </Link>
        </li>
      </ul>
      <Social sx={{ variant: `styles.header.drawer.social` }}>
        <OutboundLink
          href="https://www.facebook.com/TuFleteOficial/"
          target="_blank"
          rel="nofollow"
        >
          <GrFacebook />
        </OutboundLink>
        <OutboundLink
          href="https://www.instagram.com/tufleteoficial/"
          target="_blank"
          rel="nofollow"
        >
          <GrInstagram />
        </OutboundLink>
        <OutboundLink
          href="https://twitter.com/TuFleteOficial"
          target="_blank"
          rel="nofollow"
        >
          <GrTwitter />
        </OutboundLink>
        <OutboundLink
          href="https://www.linkedin.com/company/tuflete/"
          target="_blank"
          rel="nofollow"
        >
          <GrLinkedin />
        </OutboundLink>
      </Social>
    </div>
  </Drawer>
)

const HeaderContent = ({ showDrawer }) => (
  <header sx={{ variant: `styles.header` }}>
    <StyledContent sx={{ variant: `styles.header.container` }}>
      <FiMenu sx={{ variant: `styles.header.icon` }} onClick={showDrawer} />

      <div sx={{ variant: `styles.header.brand` }}>
        <Link to="/" activeClassName="active">
          <Logo />
        </Link>
      </div>

      <Button
        variant="menu"
        onClick={() => {
          window.open(
            "https://api.whatsapp.com/send?phone=5215527156263&text=¡Hola! Quiero cotizar un flete",
            "_blank"
          )

          typeof window !== "undefined" && window.gtag("event", "conversion")

          // trackCustomEvent({
          //   category: "Botón Cotizar x WhatsApp", //event
          //   action: "Click",                     // conversion
          //   label: "Botón Del Menú",
          // })
        }}
      >
        Cotizar
      </Button>
    </StyledContent>
  </header>
)

const StyledContent = styled(Container)`
  align-content: center;
  display: grid;
`
const Social = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`
